// import staffList from "../data/staffList";
import React, {useState,useEffect} from 'react';


function Home() {
  const [staffList,setStaffList]=useState([])

  const getStaffList = () => {
    fetch('../data/staffList.json')
    .then(response => {
      return response.json()
    }).then(staffListJson => {
      setStaffList(staffListJson)
    })
  }

  useEffect (()=> {
    getStaffList()
  },[])

  console.log(staffList)


	staffList.sort( function( a, b ) {
    a = a.lastName.toLowerCase();
    b = b.lastName.toLowerCase();

    return a < b ? -1 : a > b ? 1 : 0;
});

    

    const directory = staffList.map(person => {
        return (
            <li key={person.key}>
        		<a  href={person.firstName.toLowerCase()}>{person.firstName+" "+person.lastName}</a>
        	</li>
        )
    })



    return (
        <div className='homepage'>
	        <section id="staff-directory">
		        <h2>Staff</h2>
		        <ul>
		  	      {directory}
		  	    </ul>
	  	    </section>
        </div>
        

    )
}

export default Home;