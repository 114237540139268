import QrCreator from 'qr-creator';


function ProfilePicCard(props) {

    let qrCodeFlag = false;
    let profilePicSrc
    props.profilePic ? profilePicSrc = './images/'+props.profilePic : profilePicSrc = "./images/defaultProfile_opt.jpg"
    function cardFlipHandler(e) {
        
        let targetCard = e.target.closest('.flip-card-inner');
        let targetQRSec = targetCard.querySelector('#qr-code');
        targetCard.classList.toggle("flipped");



        if(!qrCodeFlag) {

            QrCreator.render({
            text: document.URL.replace('.html', ''),
            radius: 0.5, // 0.0 to 0.5
            ecLevel: 'H', // L, M, Q, H
            fill: '#fff', // foreground color
            background: null, // color or null for transparent
            size: targetQRSec.clientWidth // in pixels
        }, targetQRSec);



        qrCodeFlag = true;

        }
        
    }



    return (
        <section id="profile-picture">
            <div className="flip-card">
                <div className="flip-card-inner" onClick={cardFlipHandler}>
                    <div className="flip-card-front" >
                        <img id="profile-picture-img" src={profilePicSrc} alt=""/>
                        <svg viewBox="0 0 500 160">
                            <text x="100%" y="95%" dominantBaseline="auto" textAnchor="end">{props.firstName}</text>
                        </svg>
                    </div>
                    <div className="flip-card-back">
                        <div id="qr-code"></div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default ProfilePicCard