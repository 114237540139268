import {BrowserRouter,Routes, Route, Outlet} from 'react-router-dom';
import React, {useState,useEffect} from 'react';

import Header from './components/Header'
import Footer from './components/Footer'
// import staffList from "../data/staffList";

import Home from './pages/Home'
import Profile from './pages/Profile'





function App() {

  const [staffList,setStaffList]=useState([])

  const getStaffList = () => {
    fetch('../data/staffList.json')
    .then(response => {
      return response.json()
    }).then(staffListJson => {
      setStaffList(staffListJson)
    })
  }

  useEffect (()=> {
    getStaffList()
  },[])

const Layout = () => {
  return (
    <>
    <Header />
    <Outlet />
    <Footer />
    </>
    )
}


const directoryRoutes = staffList.map(person => {
  return (
    <Route key={person.key} path={person.firstName.toLowerCase()} element={<Profile person={person} /> } />
    )
})



  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="profile" element={<Profile />} />
            {directoryRoutes}        
            </Route>
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;