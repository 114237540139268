import logo from '../images/amfm-logo.svg';
function Header() {
    return (
    <header>
    	<a href="https://amfm.ca">
    		<img src={logo} alt="AMFM An Advertising Agency Logo" />
    	</a>
    </header>
    )
}

export default Header;