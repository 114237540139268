import VCard from 'vcard-creator';

function VirtualCardLink(props) {

	const profileVcard = new VCard();

	profileVcard
    // Add personal data
    .addName(props.person.lastName, props.person.firstName, '', '', '')
    // Add work data
    .addCompany('AMFM Inc.')
    .addJobtitle(props.person.jobTitle)
    .addEmail(props.person.email)
    .addPhoneNumber(props.person.workNumber, 'TYPE=PREF;WORK')
    .addPhoneNumber(props.person.mobileNumber, 'TYPE=CELL')
    .addURL(document.URL.replace('.html', ''))

    const vcfFileName = props.person.firstName+props.person.lastName

    let textFile = null;


    function makeTextFile(text) {

    	let data = new Blob([text], {type:'text/vcard'})
    	if (textFile !== null) {
    		URL.revokeObjectURL(textFile)
    	}

    	textFile = URL.createObjectURL(data)

    	return textFile


    }





    return (

        <a id="vcf-link" href={makeTextFile(profileVcard.toString())} download={vcfFileName+'.vcf'}>
        	<i className="fa-solid fa-address-card"></i>
        	<span>Add to Contacts</span>
		</a>

    )
}

export default VirtualCardLink